<template>
  <el-form :model="form" ref="form" :rules="rules">
    <ul class="form_list clearfix verification-code-container">
      <li class="verification-code fl">
        <el-form-item :label="$t('common.field.enterVerificationCode')" prop="code">
          <el-input v-model="form.code" id="verificationCode" class="plain-input" data-testid="code" />
        </el-form-item>
      </li>
      <li class="fr">
        <el-button class="el-button btn-blue" :disabled="isSendEmail" @click="sendEmail()" data-testid="code-button"
          >{{ isClickSendEmail ? $t('common.button.resendCode') : $t('common.button.sendCode') }}
          {{ displayTime > 0 ? `(${displayTime})` : '' }}</el-button
        >
      </li>
    </ul>
    <div class="text-red " v-if="errorCode !== 0">
      {{ $t(`responseMsg.${errorCode}`) }}
    </div>
    <div class="text-primary" v-if="isClickSendEmail">
      {{ $t('withdraw.default.verificationCode', { email: getEmail, supportEmail: GLOBAL_CONTACT_EMAIL }) }}
    </div>
  </el-form>
</template>

<script>
import { apiSendEmailVerificationCode } from '@/resource';

export default {
  name: 'VerificationCode',
  props: {
    namespace: String
  },
  data() {
    return {
      form: {
        code: ''
      },
      rules: {
        code: [
          {
            required: true,
            message: this.$t('common.formValidation.verCodeReq'),
            trigger: 'blur'
          }
        ]
      },
      isSendEmail: false,
      isClickSendEmail: false,
      secondTime: 60,
      displayTime: 0,
      timeInterval: null,
      errorCode: 0,
      txId: ''
    };
  },
  computed: {
    getEmail() {
      return this.$store.state.common.emailMasked;
    }
  },
  methods: {
    sendEmail() {
      apiSendEmailVerificationCode({ namespace: this.namespace }).then(resp => {
        this.isSendEmail = true;
        this.isClickSendEmail = true;
        this.startTimer();
        if (resp.data.code === 0) this.txId = resp.data.data.txId;
        else this.errorCode = resp.data.code;
      });
    },
    startTimer() {
      this.displayTime = this.secondTime;
      this.timeInterval = setInterval(this.countDown, 1000);
    },
    countDown() {
      this.displayTime--;
      if (this.displayTime === 0) this.stopTimer();
    },
    stopTimer() {
      clearInterval(this.timeInterval);
      this.isSendEmail = false;
    },
    getFormDataOfChild() {
      return {
        txId: this.txId,
        code: this.form.code
      };
    },
    submitForm() {
      return this.$refs['form'].validate();
    }
  }
};
</script>

<style lang="scss" scoped>
.verification-code-container {
  display: flex;
  .verification-code {
    @include rtl-sass-prop(margin-right, margin-left, 1rem);
  }
  .el-button {
    margin-top: 22px;
  }
}

@media (max-width: 630px) {
  .verification-code-container {
    flex-direction: column;
    .verification-code {
      margin-right: 0;
    }
    .el-button {
      margin-top: 0;
      margin-bottom: 1rem;
    }
  }
}
</style>
