<template>
  <el-form :model="form" label-position="top" :rules="rulesSkrill" ref="withdrawFormSkrill">
    <div class="form-list-inner clearfix">
      <ul class="clearfix">
        <li>
          <el-form-item :label="$t('withdraw.skrillEmail')" prop="skrillEmail">
            <el-input
              maxlength="100"
              type="text"
              v-model="form.skrillEmail"
              auto-complete="off"
              data-testid="skrillEmail"
            ></el-input>
          </el-form-item>
        </li>
        <li>
          <el-form-item :label="$t('withdraw.ImportantNotes')">
            <el-input
              maxlength="100"
              type="text"
              v-model="form.importantNotes"
              auto-complete="off"
              data-testid="importantNotes"
            ></el-input>
          </el-form-item>
        </li>
      </ul>
      <div class="alert-info">
        {{ $t('withdraw.SkrillChargeAlert') }}
      </div>
    </div>
  </el-form>
</template>

<script>
export default {
  data() {
    return {
      form: {
        skrillEmail: '',
        importantNotes: ''
      },
      rulesSkrill: {
        skrillEmail: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.email'),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  watch: {
    form: {
      handler(val, oldVal) {
        this.$emit('formChange', val);
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      return this.$refs.withdrawFormSkrill.validate();
    }
  }
};
</script>
