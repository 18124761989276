<template>
  <el-form :model="form" label-position="top" :rules="rules" ref="withdrawFormChild">
    <div class="form-list-inner clearfix">
      <ul class="clearfix">
        <li>
          <el-form-item :label="$t('withdraw.BitwalletEmail')" prop="bitwalletEmail">
            <el-input
              maxlength="100"
              type="text"
              v-model="form.bitwalletEmail"
              auto-complete="off"
              data-testid="bitwalletEmail"
            ></el-input>
          </el-form-item>
        </li>
        <li>
          <el-form-item :label="$t('withdraw.ImportantNotes')">
            <el-input
              maxlength="100"
              type="text"
              v-model="form.importantNotes"
              auto-complete="off"
              data-testid="importantNotes"
            ></el-input>
          </el-form-item>
        </li>
      </ul>
    </div>
  </el-form>
</template>

<script>
import { validateEmail } from '@/util/validators';

export default {
  data() {
    return {
      form: {
        bitwalletEmail: '',
        importantNotes: ''
      },
      rules: {
        bitwalletEmail: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.email'),
            trigger: 'blur'
          },
          {
            validator: validateEmail(this.$t('withdraw.formErrorMsg.emailFormat')),
            trigger: 'blur'
          }
        ]
      }
    };
  },
  watch: {
    form: {
      handler(val, oldVal) {
        this.$emit('formChange', val);
      },
      deep: true
    }
  },
  methods: {
    submitForm() {
      return this.$refs.withdrawFormChild.validate();
    }
  }
};
</script>
