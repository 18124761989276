import { render, staticRenderFns } from "./Sticpay.vue?vue&type=template&id=7953ac23&"
import script from "./Sticpay.vue?vue&type=script&lang=js&"
export * from "./Sticpay.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports