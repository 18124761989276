<template>
  <el-form :model="form" label-position="top" :rules="rulesInternational" ref="withdrawFormInternational">
    <div>
      <div class="form-list-inner clearfix">
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('withdraw.BankName')" prop="bankName">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.bankName"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="bankName"
              ></el-input>
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('withdraw.BankAddress')" prop="bankAddress">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.bankAddress"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="bankAddress"
              ></el-input>
            </el-form-item>
          </li>
        </ul>
      </div>
      <div class="form-list-inner clearfix">
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('withdraw.AccountNumberIBAN')" prop="accountNumber">
              <el-input
                maxlength="100"
                type="text"
                v-model.trim="form.accountNumber"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="accountNumber"
              ></el-input>
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('withdraw.BankAccountBeneficiaryName')" prop="beneficiaryName">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.beneficiaryName"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="beneficiaryName"
              ></el-input>
            </el-form-item>
          </li>
        </ul>
      </div>
      <div class="form-list-inner clearfix">
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('withdraw.AccountHoldersAddress')" prop="holderAddress">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.holderAddress"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="holderAddress"
              ></el-input>
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('withdraw.Swift')" prop="swift">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.swift"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="swift"
              ></el-input>
            </el-form-item>
          </li>
        </ul>
      </div>
      <div class="form-list-inner clearfix" v-if="userCountryCode == canCountryCode">
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('withdraw.transitNumber')" prop="transitNumber">
              <el-input v-model="canadaOnly.transitNumber" data-testid="transitNumber"></el-input>
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('withdraw.institutionNumber')" prop="institutionNumber">
              <el-input v-model="canadaOnly.institutionNumber" data-testid="institutionNumber"></el-input>
            </el-form-item>
          </li>
        </ul>
      </div>
      <div class="form-list-inner clearfix">
        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('withdraw.ABA_SortCodeABA')">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.sortCode"
                auto-complete="off"
                :disabled="isdisabled"
                data-testid="sortCode"
              ></el-input>
            </el-form-item>
          </li>
          <li>
            <el-form-item :label="$t('withdraw.ImportantNotes')">
              <el-input
                maxlength="100"
                type="text"
                v-model="form.importantNotes"
                auto-complete="off"
                data-testid="importantNotes"
              ></el-input>
            </el-form-item>
          </li>
        </ul>

        <ul class="clearfix">
          <li>
            <el-form-item :label="$t('withdraw.transfer.label.upload')" class="upload" prop="uploadedFile">
              <vUpload :limit="1" v-on:updateFileInfo="updateFileInfo" />
            </el-form-item>
          </li>
          <li>
            <p class="sys-info" v-html="$t('withdraw.transfer.info', { platform: $config.info.fullName })"></p>
          </li>
        </ul>

        <div class="checkbox_wrapper" v-if="!form.userPaymentInfoId">
          <el-checkbox v-model="form.isRememberInfo" data-testid="checkbox">
            {{ $t('withdraw.default.remember') }}
          </el-checkbox>
        </div>
      </div>
    </div>
  </el-form>
</template>

<script>
import bankTransferMixin from '@/mixins/withdrawl/bankTransfer';
import withdrawlMixin from '@/mixins/withdrawl/withdrawl';
import vUpload from '@/components/vUpload';

export default {
  props: {
    userCountryCode: String,
    withdrawalType: Number,
    chooseWithdrawalType: Object,
    selectedCardInfo: Object,
    transferCountryString: String
  },
  mixins: [bankTransferMixin, withdrawlMixin],
  components: { vUpload },
  data() {
    const validateFile = (rule, value, callback) => {
      if (this.form.fileList.length < 1) callback(new Error(this.$t('withdraw.transfer.formValidation.upload')));
      else callback();
    };

    return {
      transferCountry: null,
      form: {
        country: 2,
        bankName: '',
        bankAddress: '',
        accountNumber: '',
        beneficiaryName: '',
        holderAddress: '',
        swift: '',
        sortCode: '',
        importantNotes: '',
        userPaymentInfoId: '',
        fileList: []
      },
      rulesInternational: {
        bankName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.bankName'),
            trigger: 'blur'
          }
        ],
        bankAddress: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.bankAddress'),
            trigger: 'blur'
          }
        ],
        accountNumber: [
          {
            required: true,
            validator: this.validateAccountNumber,
            trigger: 'blur'
          }
        ],
        beneficiaryName: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.beneName'),
            trigger: 'blur'
          }
        ],
        holderAddress: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.holderAddress'),
            trigger: 'blur'
          }
        ],
        swift: [
          {
            required: true,
            message: this.$t('withdraw.formErrorMsg.swift'),
            trigger: 'blur'
          }
        ],
        uploadedFile: [
          {
            required: true,
            validator: validateFile,
            trigger: 'change'
          }
        ]
      },
      canadaOnly: {
        transitNumber: '',
        institutionNumber: ''
      },
      canCountryCode: 4575 // Canada
    };
  },
  watch: {
    form: {
      handler(val, oldVal) {
        this.$emit('formChange', val);
      },
      deep: true,
      immediate: true
    },
    selectedCardInfo: {
      immediate: true,
      handler(value) {
        console.log(value);
        if (value && value.id !== -1) {
          this.$nextTick(() => {
            this.$refs['withdrawFormInternational'].clearValidate();
          });

          var notesObj = {};
          if (value.importantNotes && this.userCountryCode == this.canCountryCode) {
            var properties = value.importantNotes.split(', ');
            properties.forEach(function(property) {
              var tup = property.split(':');
              notesObj[tup[0]] = tup[1];
            });
            console.log(notesObj);
            this.canadaOnly = {
              transitNumber: this.selectedCardInfo.importantNotes ? notesObj['Transit Number'] : '',
              institutionNumber: this.selectedCardInfo.importantNotes ? notesObj['Institution Number'] : ''
            };
          }
          this.form = {
            country: this.form.country,
            bankName: this.selectedCardInfo.bankName,
            bankAddress: this.selectedCardInfo.bankAddress,
            accountNumber: this.selectedCardInfo.accountNumber,
            beneficiaryName: this.selectedCardInfo.beneficiaryName,
            holderAddress: this.selectedCardInfo.holderAddress,
            swift: this.selectedCardInfo.swift,
            sortCode: this.selectedCardInfo.sortCode,
            importantNotes:
              this.selectedCardInfo.importantNotes && this.userCountryCode == this.canCountryCode
                ? notesObj['notes']
                : this.selectedCardInfo.importantNotes && this.userCountryCode != this.canCountryCode
                ? this.selectedCardInfo.importantNotes
                : '',
            isRememberInfo: this.selectedCardInfo.id ? '' : this.form.isRememberInfo,
            userPaymentInfoId: this.selectedCardInfo.id ? this.selectedCardInfo.id : '',
            fileList: []
          };
          this.isdisabled = true;
        } else {
          this.isdisabled = false;
          this.form.userPaymentInfoId = '';
        }
      }
    }
  },
  methods: {
    submitForm() {
      return this.$refs.withdrawFormInternational.validate();
    },
    updateFileInfo(fileInfo) {
      this.form.fileList = fileInfo.fileList;
      this.$refs['withdrawFormInternational'].validateField('uploadedFile');
    }
  }
};
</script>
