<template>
  <div :class="{ active: selectedType == type.id }" @click="click(type.id)" :data-testid="testId + type.id">
    <div class="left"></div>
    <div class="right">
      <!-- <div class="logo" :class="isChangeBKImg(type.id) ? `img9` : `img${type.id}`"> -->
      <div class="logo" :class="getLogo(type.id)">
        <div class="img"></div>
      </div>
      <span>{{ $t(type.nameKey) }}</span>
      <!-- CANADIAN BANKING ONLY -->
      <p class="bottom" v-if="isCanBank(type.id)">
        {{ $t('common.withdrawChannel.internetbankingcanadatm') }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'withdrawTypeList',
  props: {
    type: {},
    selectedType: {},
    countryCode: 0,
    testId: ''
  },
  methods: {
    isChangeBKImg(id) {
      //change svg nigeria bnak transfer
      return id === 2 && this.regulator === 'svg' && this.countryCode == this.ngaCountryCode;
    },
    isCanBank(id) {
      return id == `60`;
    },
    getLogo(id) {
      switch (true) {
        case this.isChangeBKImg(id):
          return 'img9';
        case this.isCanBank(id):
          return `logoLg img${id}`;
        default:
          return `img${id}`;
      }
    },
    click(id) {
      this.$emit('changeType', id);
    }
  }
};
</script>
