<template>
  <el-form :model="withdrawForm" label-position="top" :rules="rulesWithdraw" ref="withdrawForm">
    <el-form-item :label="$t('common.keys.rebateAccount')" prop="qAccount">
      <el-select v-model="withdrawForm.qAccount" @change="rebateAccountChange">
        <el-option
          v-for="item in accountList"
          :key="item.login"
          :label="item.login + ' - ' + item.balance + ' ' + item.currency"
          :value="item.login"
        ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item :label="amtLabel" prop="amount">
      <numeric-input
        ref="numberInput"
        v-model="withdrawForm.amount"
        :currency="withdrawForm.currency"
        :precision="2"
      ></numeric-input>
    </el-form-item>
    <el-form-item :label="$t('withdraw.PleaseSelectDesiredWithdrawMethod')">
      <el-select v-model="withdrawForm.withdrawalType" placeholder="请选择" class="select_withdraw" popper-class="withdraw_select">
        <el-option v-for="item in withdrawalSupportedTypes" :key="item.id" :label="$t(item.nameKey)" :value="item.id"></el-option>
      </el-select>
    </el-form-item>
  </el-form>
</template>

<script>
import NumericInput from '@/components/NumericInput';
import withdrawTypeList from '@/components/rebateWithdraw/withdrawTypeList/withdrawTypeList.vue';
import { integerOrMaxTwoDecimalNumbersCreator } from '@/util/validators';
import { apiGetWithdrawalData, apiQueryRateUSDByTargetCurrency } from '@/resource';
import { countryCodeEnum } from '@/constants/country';

export default {
  name: 'top',
  components: { NumericInput, withdrawTypeList },
  props: { chooseWithdrawalType: Object },
  data() {
    return {
      accountList: [],
      withdrawalTypes: [],
      withdrawalSupportedTypes: [],
      countryBankList: null,
      countryCode: null,
      availableBalance: '',
      withdrawForm: {
        qAccount: '',
        amount: '',
        currency: '',
        withdrawalType: ''
      },
      ngaCountryCode: 5796,
      canCountryCode: 4575,
      ngaSupportCurrencies: ['USD', 'USC'],
      UnionpaySupportCurrencies: ['USD', 'USC'],
      minLimit: this.$config.withdrawalDefaultAmountMin, // current minLimit
      apiAmountLimit: 0, // record amountLimit from props chooseWithdrawalType.amountLimit
    };
  },
  watch: {
    withdrawForm: {
      handler(val, oldVal) {
        this.$emit('withdrawFormChange', val);
      },
      deep: true
    },
    chooseWithdrawalType: {
      deep: true,
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.apiAmountLimit = newValue?.amountLimit;
          this.convertUSDToTargetCurrency(this.withdrawForm.currency);
        }
      }
    },
    'withdrawForm.currency': {
      immediate: true,
      async handler(newValue) {
        if (newValue) {
          await this.convertUSDToTargetCurrency(newValue);
          if (this.withdrawForm.amount) this.$refs.withdrawForm.clearValidate();
        }
      }
    }
  },
  computed: {
    rulesWithdraw() {
      return {
        qAccount: [{ required: true, message: this.$t('common.keys.pleaseSelect'), trigger: 'change' }],
        amount: [
          { required: true, message: this.$t('withdraw.formErrorMsg.amt'), trigger: 'blur' },
          { validator: integerOrMaxTwoDecimalNumbersCreator(this.$t('withdraw.CannotUseMoreThanTwoDecimalValues')), rigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (parseFloat(value) <= 0) {
                // CANADA ONLY
                if (this.chooseWithdrawalType?.id == '60') {
                  callback(
                    new Error(
                      this.$t('withdraw.formErrorMsg.amtCAD', {
                        minLimit: this.minLimit,
                        currency: 'CAD'
                      })
                    )
                  );
                } else {
                  callback(new Error(this.$t('withdraw.formErrorMsg.amt0')));
                }
              } else if (value < this.minLimit) {
                // CANADA ONLY
                if (this.chooseWithdrawalType?.id == '60') {
                  callback(
                    new Error(
                      this.$t('withdraw.formErrorMsg.amtCAD', {
                        minLimit: this.minLimit,
                        currency: 'CAD'
                      })
                    )
                  );
                } else {
                  callback(
                    new Error(
                      this.$t('withdraw.amtLarger', {
                        minLimit: this.minLimit,
                        currency: this.withdrawForm.currency
                      })
                    )
                  );
                }
              } else if (value > this.availableBalance) {
                callback(new Error(this.$t('withdraw.YourPaymentGreaterThanAvailableBalance')));
              }
              callback();
            },
            trigger: 'blur'
          }
        ]
      };
    },
    amtLabel() {
      return this.withdrawForm.currency === 'USC'
        ? `${this.$t('common.keys.amount')}    ${this.$t('common.keys.actualAmonut')}= ${this.withdrawForm.amount /
            100} USD`
        : this.$t('common.keys.amount');
    },
    regulator() {
      return this.$store.state.common.regulator;
    }
  },
  mounted() {
    this.getWithdrawalData();
  },
  methods: {
    getWithdrawalData() {
      apiGetWithdrawalData({
        qUserId: this.$store.state.common.CUID
      }).then(resp => {
        if (resp.data.code === 0) {
          const accounts = resp.data.data.logins;
          this.withdrawalTypes = resp.data.data.withdrawalType;
          this.countryCode = resp.data.data.countryCode;
          this.accountList = accounts.filter(acc => acc.accountDealType === 3);
          this.countryBankList = resp.data.data.withdrawBankList;
          
          this.$emit('setCountryCode', this.countryCode);
          this.$emit('setBankList', this.countryBankList);
          this.withdrawalSupportedTypes = this.withdrawalTypes;
          this.formateWithdrawalTypes(this.withdrawalSupportedTypes);
          
          if (this.accountList && this.accountList.length > 0) {
            this.$nextTick(() => {
              // 默认选中首页选中的账号
              this.$set(this.withdrawForm, 'qAccount', this.accountList[0].login);
              this.rebateAccountChange(this.accountList[0].login);
            });
          }
        }
      });
    },
    formateWithdrawalTypes(list) {
      const allowIBTWithdrawal = this.$config.allowIBTWithdrawal({
        reg: this.regulator,
        countryCode: this.$store.state.common.countryCode,
        type: 'WITHDRAW'
      });
      list.forEach((el, index) => {
        if (el.category === 1) {
          if (el.id === 2) {
            if (!allowIBTWithdrawal) {
              list.splice(index, 1);
            }
          }
        }
      });
      return list
    },
    rebateAccountChange(val) {
      if (val === '') {
        return;
      }

      const [currAccountInfo] = this.accountList.filter(item => {
        return item.login === val;
      });

      if (currAccountInfo) {
        this.availableBalance = currAccountInfo.balance;
        this.withdrawForm.currency = currAccountInfo.currency;
      }

      this.withdrawForm.withdrawalType = null;

      this.filterWithdrawalSupportedTypes(this.withdrawForm.currency);

      this.$nextTick(() => {
        if (this.withdrawForm.amount) {
          this.$refs.withdrawForm.validateField('amount', valid => {
            if (!valid) {
              return false;
            }
          });
        }
      });
    },
    filterWithdrawalSupportedTypes(currency) {
      if (!this.UnionpaySupportCurrencies.includes(currency)) {
        this.withdrawalSupportedTypes = this.withdrawalTypes.filter(type => type.id !== 4);
      } else {
        this.withdrawalSupportedTypes = this.withdrawalTypes;
      }

      if (this.filterNgaWithdrawTypes(currency)) {
        this.withdrawalSupportedTypes = this.withdrawalSupportedTypes.filter(type => type.id !== 2);
      }
      if (this.filterCanWithdrawTypes(currency)) {
        this.withdrawalSupportedTypes = this.withdrawalSupportedTypes.filter(type => type.id !== 60);
      }
      // filter Perfect Money (id = 61)
      if (this.filterPerfectMoneyType(currency)) {
        this.withdrawalSupportedTypes = this.withdrawalSupportedTypes.filter(type => type.id !== 61);
      }
    },
    submitForm() {
      return this.$refs.withdrawForm.validate();
    },
    isChangeBKImg(id) {
      //change svg nigeria bnak transfer
      return id === 2 && this.regulator === 'svg' && this.countryCode == this.ngaCountryCode;
    },
    filterNgaWithdrawTypes(currency) {
      return (
        this.regulator === 'svg' &&
        this.countryCode == this.ngaCountryCode &&
        !this.ngaSupportCurrencies.includes(currency)
      );
    },
    filterCanWithdrawTypes(currency) {
      return this.countryCode == this.canCountryCode && currency !== 'CAD';
    },
    filterPerfectMoneyType(currency) {
      const allowedCurrencies = ['USD', 'USC', 'EUR'];
      return currency ? !allowedCurrencies.includes(currency) : true;
    },
    convertUSDToTargetCurrency(currency, equalValue = this.$config.withdrawalDefaultAmountMin) {
      if (!this.$config.usdRateFromApiCurrencyList.includes(currency)) {
        this.minLimit = this.apiAmountLimit ? this.apiAmountLimit : equalValue;
        return;
      }

      return apiQueryRateUSDByTargetCurrency(currency)
        .then(res => {
          if (res.data.code === 0 && res.data.data) {
            this.minLimit = Math.ceil(equalValue * Number(res.data.data));
          } else {
            this.minLimit = equalValue;
          }
        })
        .catch(error => {
          this.minLimit = equalValue;
        });
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
