<template>
  <div class="div_dialog">
    <el-dialog :visible.sync="show" :close-on-click-modal="false" :close-on-press-escape="false" top="0">
      <div slot="title">
        <slot name="header"></slot>
      </div>
      <div class="dialog_body">
        <slot></slot>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'vDialog',
  props: {
    show: Boolean,
    text: String,
    method: String
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/vDialog.scss';
</style>
